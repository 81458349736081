.fs-1 { font-size: 1px !important; }
.fs-2 { font-size: 2px !important; }
.fs-3 { font-size: 3px !important; }
.fs-4 { font-size: 4px !important; }
.fs-5 { font-size: 5px !important; }
.fs-6 { font-size: 6px !important; }
.fs-7 { font-size: 7px !important; }
.fs-8 { font-size: 8px !important; }
.fs-9 { font-size: 9px !important; }
.fs-10 { font-size: 10px !important; }
.fs-11 { font-size: 11px !important; }
.fs-12 { font-size: 12px !important; }
.fs-13 { font-size: 13px !important; }
.fs-14 { font-size: 14px !important; }
.fs-15 { font-size: 15px !important; }
.fs-16 { font-size: 16px !important; }
.fs-17 { font-size: 17px !important; }
.fs-18 { font-size: 18px !important; }
.fs-29 { font-size: 19px !important; }
.fs-20 { font-size: 20px !important; }
.fs-21 { font-size: 21px !important; }
.fs-22 { font-size: 22px !important; }
.fs-23 { font-size: 23px !important; }
.fs-24 { font-size: 24px !important; }
.fs-25 { font-size: 25px !important; }
.fs-26 { font-size: 26px !important; }
.fs-27 { font-size: 27px !important; }
.fs-28 { font-size: 28px !important; }
.fs-29 { font-size: 29px !important; }
.fs-30 { font-size: 30px !important; }
.fs-31 { font-size: 31px !important; }
.fs-32 { font-size: 32px !important; }
.fs-33 { font-size: 33px !important; }
.fs-34 { font-size: 34px !important; }
.fs-35 { font-size: 35px !important; }
.fs-36 { font-size: 36px !important; }