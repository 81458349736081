// border radius
.radius-1{border-radius: 1px;}
.radius-2{border-radius: 2px;}
.radius-3{border-radius: 3px;}
.radius-4{border-radius: 4px;}
.radius-5{border-radius: 5px;}
.radius-6{border-radius: 6px;}
.radius-7{border-radius: 7px;}
.radius-8{border-radius: 8px;}
.radius-9{border-radius: 9px;}
.radius-10{border-radius: 10px;}
.radius-12{border-radius: 12px;}
.radius-15{border-radius: 15px;}
.radius-20{border-radius: 20px;}
.radius-50{border-radius: 50px;}
.radius-100{border-radius: 100px;}


// border radius top-right
.radius-top-right-1{border-top-right-radius: 1px;}
.radius-top-right-2{border-top-right-radius: 2px;}
.radius-top-right-3{border-top-right-radius: 3px;}
.radius-top-right-4{border-top-right-radius: 4px;}
.radius-top-right-5{border-top-right-radius: 5px;}
.radius-top-right-6{border-top-right-radius: 6px;}
.radius-top-right-7{border-top-right-radius: 7px;}
.radius-top-right-8{border-top-right-radius: 8px;}
.radius-top-right-9{border-top-right-radius: 9px;}
.radius-top-right-10{border-top-right-radius: 10px;}
.radius-top-right-20{border-top-right-radius: 20px;}
.radius-top-right-50{border-top-right-radius: 50px;}
.radius-top-right-100{border-top-right-radius: 100px;}


// border radius top-left
.radius-top-left-1{border-top-left-radius: 1px;}
.radius-top-left-2{border-top-left-radius: 2px;}
.radius-top-left-3{border-top-left-radius: 3px;}
.radius-top-left-4{border-top-left-radius: 4px;}
.radius-top-left-5{border-top-left-radius: 5px;}
.radius-top-left-6{border-top-left-radius: 6px;}
.radius-top-left-7{border-top-left-radius: 7px;}
.radius-top-left-8{border-top-left-radius: 8px;}
.radius-top-left-9{border-top-left-radius: 9px;}
.radius-top-left-10{border-top-left-radius: 10px;}
.radius-top-left-20{border-top-left-radius: 20px;}
.radius-top-left-50{border-top-left-radius: 50px;}
.radius-top-left-100{border-top-left-radius: 100px;}


// border radius bottom-right
.radius-bottom-right-1{border-bottom-right-radius: 1px;}
.radius-bottom-right-2{border-bottom-right-radius: 2px;}
.radius-bottom-right-3{border-bottom-right-radius: 3px;}
.radius-bottom-right-4{border-bottom-right-radius: 4px;}
.radius-bottom-right-5{border-bottom-right-radius: 5px;}
.radius-bottom-right-6{border-bottom-right-radius: 6px;}
.radius-bottom-right-7{border-bottom-right-radius: 7px;}
.radius-bottom-right-8{border-bottom-right-radius: 8px;}
.radius-bottom-right-9{border-bottom-right-radius: 9px;}
.radius-bottom-right-10{border-bottom-right-radius: 10px;}
.radius-bottom-right-20{border-bottom-right-radius: 20px;}
.radius-bottom-right-50{border-bottom-right-radius: 50px;}
.radius-bottom-right-100{border-bottom-right-radius: 100px;}


// border radius bottom-left
.radius-bottom-left-1{border-bottom-left-radius: 1px;}
.radius-bottom-left-2{border-bottom-left-radius: 2px;}
.radius-bottom-left-3{border-bottom-left-radius: 3px;}
.radius-bottom-left-4{border-bottom-left-radius: 4px;}
.radius-bottom-left-5{border-bottom-left-radius: 5px;}
.radius-bottom-left-6{border-bottom-left-radius: 6px;}
.radius-bottom-left-7{border-bottom-left-radius: 7px;}
.radius-bottom-left-8{border-bottom-left-radius: 8px;}
.radius-bottom-left-9{border-bottom-left-radius: 9px;}
.radius-bottom-left-10{border-bottom-left-radius: 10px;}
.radius-bottom-left-20{border-bottom-left-radius: 20px;}
.radius-bottom-left-50{border-bottom-left-radius: 50px;}
.radius-bottom-left-100{border-bottom-left-radius: 100px;}
