// sizing width
.w-95{width: 95%;}
.w-90{width: 90%;}
.w-85{width: 85%;}
.w-80{width: 80%;}
.w-75{width: 75%;}
.w-70{width: 70%;}
.w-65{width: 65%;}
.w-60{width: 60%;}
.w-55{width: 55%;}
.w-50{width: 50%;}
.w-45{width: 45%;}
.w-40{width: 40%;}
.w-35{width: 35%;}
.w-30{width: 30%;}
.w-25{width: 25%;}
.w-20{width: 20%;}
.w-15{width: 15%;}
.w-10{width: 10%;}
.w-5{width: 5%;}

// sizing height
.h-95{height: 95%;}
.h-90{height: 90%;}
.h-85{height: 85%;}
.h-80{height: 80%;}
.h-75{height: 75%;}
.h-70{height: 70%;}
.h-65{height: 65%;}
.h-60{height: 60%;}
.h-55{height: 55%;}
.h-50{height: 50%;}
.h-45{height: 45%;}
.h-40{height: 40%;}
.h-35{height: 35%;}
.h-30{height: 30%;}
.h-25{height: 25%;}
.h-20{height: 20%;}
.h-15{height: 15%;}
.h-10{height: 10%;}
.h-5{height: 5%;}