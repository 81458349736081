.right-modal {
    position: fixed;
    height: 100%;
    top: 0;
    margin: 0;
    right: -680px;
    transform: translate(0, 0) !important;
    width: 550px;
    transition: all 0.3s linear;
    left: auto;
    z-index: 1041;
    opacity: 1 !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    &.show {
        right: 0;
    }
    .modal-dialog {
        margin: 0;
        transform: translate(0, 0) !important;
        transition: none !important;
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        .modal-content {
            border-radius: 0;
            max-height: 100%;
            .modal-header {
                border-radius: 0;
            }
        }
    }
}
.left-modal {
    position: fixed;
    height: 100%;
    top: 0;
    margin: 0;
    left: -680px;
    transform: translate(0, 0) !important;
    width: 550px;
    transition: all 0.3s linear;
    right: auto;
    z-index: 1041;
    opacity: 1 !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    &.show {
        left: 0;
    }
    .modal-dialog {
        margin: 0;
        transform: translate(0, 0) !important;
        transition: none !important;
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        .modal-content {
            border-radius: 0;
            max-height: 100%;
            .modal-header {
                border-radius: 0;
            }
        }
    }
}