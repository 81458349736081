table tr td {
  color: #333;
  font-size: 0.875rem;
  padding: 8px;
}
.k-grid th,
.k-grid td {
  color: #333;
  font-size: 0.875rem;
  padding: 8px;
}
.k-grid-content tr:last-child > td,
.k-grid-content-locked tr:last-child > td {
  border-bottom-width: 1px;
}
.k-grid th,
.k-grid td {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.k-grid {
  font-family: "Poppins" !important;
}
.fill-row {
  background: #f0f7fb;
}
.tr-show {
  display: table-row !important;
}
.tr-player {
  display: none;
}
// .table-overflow-hedden start
.table-overflow-hedden .k-grid-header,
.k-grid-footer {
  padding-inline-end: var(--kendo-scrollbar-width, 0);
  padding-right: 0;
}
.table-overflow-hedden .k-grid-content {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}
// .table-overflow-hedden end
.k-clear-value {
  height: calc(12px + 12px) !important;
}
.k-input-inner {
  height: calc(14px + 14px);
}
.card-header {
  font-size: 18px;
  padding: 11px 17px 12px 17px;
}
.k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid tbody > tr:not(.k-detail-row).k-state-hover,
.k-grid tbody > tr:not(.k-detail-row).k-hover {
  //background: rgba($primary, 0.06);
  background: var(--color-main-hover);
}
.highlightRow {
  background: rgba(122, 162, 191, 0.1) !important;
}
.highlightRowPlayer tr.k-detail-row {
  background: rgba(122, 162, 191, 0.1) !important;
}
.rotate-direction-i {
  transform: rotate(-30deg);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.borderLeftSecondChild.table-mobile .k-grid th:nth-child(2) {
  border-left: 0;
}
.borderLeftSecondChild.table-mobile .k-grid td:nth-child(2) {
  border-left: 0;
}
.borderLeftThirdChild .k-grid th:nth-child(3) {
  border-left: 0;
}
.borderLeftThirdChild .k-grid td:nth-child(3) {
  border-left: 0;
}
.listRowMobile {
  display: none;
  float: left;
  width: 100%;
  padding: 10px 0 10px 0;
}
.spanLabel {
  float: left;
}
.word-break-all {
  word-break: break-all;
}
.k-pager-sizes .k-dropdownlist {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 3px !important;
}
table .k-icon.k-i-sort-asc-small {
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 701px) and (max-width: 1280px) {
  .maxTableCol .table-overflow-hedden .k-grid-header,
  .k-grid-footer {
    width: 1502px;
  }
  .maxTableCol .table-overflow-hedden .k-widget.k-grid {
    width: 1502px;
  }
  .maxTableCol .table-overflow-hedden .k-grid .k-grid-container {
    width: 1502px;
  }
}
