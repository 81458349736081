// transcript new
.btn-soundOnOff:focus {
    box-shadow:none !important;
}
.btn-playPause:focus {
    box-shadow:none !important;
}
.btn-analytics{
    transition: all .3s;
    box-shadow: 0px 0px 6px -1px rgba(0,0,0,.2);
    color: mediumblue;
    text-shadow:  0px 2px 4px rgba(173, 0, 144, 0.5);
}
.btn-analytics:hover{
    transition: all .3s;
    box-shadow: 0px 4px 12px -1px rgba(0,0,0,.25);
}
.btn-analytics:focus{
    transition: all .3s;
    background:blueviolet;
    box-shadow: 0px 0px 12px -1px rgba(0,0,0,.25);
    color: rgb(255, 255, 255);
    text-shadow:  0px 3px 3px rgba(255, 255, 255, 1);
}
.hide1{
    display: none;
}
.transcription1 {
    top: 62vh;
}
.transcription2 {
    top: 8vh;
}
.floatAnalytics{
    z-index: 100 !important;
    padding: .5rem 1rem 0 1rem;
    position: fixed;
    width: 50%;
    top: 0px;
    transform: scale(.8);
    background: white;
    border-radius: 1rem;
    box-shadow:0px 0px 10px -4px rgba(0,0,0,.2); ;
}
/* transcript */
.spkTrk {
float: left;
width: 100%;
padding: 0 0 20px 0;
margin: 0;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
text-align: justify;
opacity: .4;
}
.spkTrk.active {
  opacity: 1;
}
.spkTrk.active .spkTrk-para {
  border-left: 2px solid rgba(0,0,0,.35);
  color: #333;
}
.spkTrk-h {
  width: 95px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 50px;
}
.spkTrk-txt {
  width: 95%;
}
.spkTrk-time {
  font-size: 12px;
  padding: 0;
}
.spkTrk-para {
  padding: 0 0 0 15px;
  color: #333;
  line-height: 1.5em;
  font-size: 14px;
  border-left: 2px solid #fff;
}
.spkTrk-para:focus-visible {
  outline: none;
}