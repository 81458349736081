.errorContainer {
    position: relative;
    background: #fff;
    height: 100%;
    top: 55px;
  }
  .errorImg img {
    background-size: cover;
  }
  @media (min-width: 767px) {
    .errorContainer {
      position: fixed;
    }
  }
  