.btn-white{
    &:hover{
        color: #212529;
        text-decoration: none;
        background: #f0f0f0;
    }
}

.evalFullscreen{
    &:hover{

        transition: .2s;
        color: blueviolet;
        transform: scale(2);
        z-index: 1;
        position: absolute;
        top: 4.5em;
        right: .5em; 
    }
    transition: .2s;
    color:grey;
    transform: scale(1.7);
    z-index: 1;
    position: absolute;
    top: 4.2em;
    right: .2em;
}
.playerFloat{
    max-width: 900px;
    display: none;
    transition: all .3s;
    margin: 0 auto .75rem auto;
    padding: .25em 0;
    background: white;
    border-radius: 1em;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: .1);
}

.wrapper {
    transition: all .3s;
    display: flex;
    height: 100%;
}

.evalScoreForm {
    overflow: auto;
    border-radius: .5rem;
    flex: 1 1 70%;
    min-width: 464px;
    margin-right: .5rem;
    transition: all .3s;
}
.evalTimeStamp{
    line-height: 1;
}
.evalNavItem{
  border: solid 1px rgba($color: #aaaaaa, $alpha: .5);
  margin-right: .25em;
  border-radius: .25em .25em 0 0;
}
.evalTabContent {
    padding: .5em;
    border: solid 1px rgba($color: #aaaaaa, $alpha: .5);
  border-radius: 0 .25em .25em .25em;
}
.largeScorePan{
    &:hover{
        transition: all .3s;
        button{
            
            color:blueviolet ;
        }
        transform: scale(1.6);
        position: absolute;
    }
    transition: all .3s;
    padding: .5em;
    transform: scale(1.4);
    position: absolute;
    button{
        color: rgb(170, 170, 170);
    }
}

.largeScorePan1{
    &:hover{
        transition: all .3s;
        color:blueviolet ;
        margin-right: -5px;
        transform: scale(1.3);
    }
    transition: all .3s;
    color: rgb(170, 170, 170);
}

//footer eval form

.evalFooterBtn{
    .autoSaveTimer{
        span{
            #autoSaveTimer{
                font-size: .7rem;
                color: green;
            }
            font-size: .6rem;
        }
    }
}

//side pan
.evalSidePane {
    border-radius: .5rem;
    flex: 1 1 30%;
    margin-left: .5rem;
    transition: all .3s;
}

.evalScoreForm.active {
    transition:all .3s;
    border-radius: .5rem;
    flex: 1 1 100%;
    min-width: 464px;
    margin-right: 0;
    
}

.evalSidePane.active {
    transition: all .3s;
    display: none;
   
}

.evalScoreForm2.active2 {
    transition:all .3s;
    display: none;
    
}

.evalSidePane2.active2 {
    transition: all .3s;
    overflow: auto;
    border-radius: .5rem;
    flex: 1 1 100%;
    margin-left: .5rem;
    
    margin-left: 0;
   
}

.largeSidePan{
    &:hover{
        transition: all .3s;
        button{
            color:blueviolet ;
        }
        top: 4px;
        left: -10px;
        transform: scale(1.6);
        position: absolute;
    }
    transition: all .3s;
    padding: .5em;
    transform: scale(1.4);
    position: absolute;
    top: 4px;
    left: -15px;
    button{
        color: rgb(170, 170, 170);
    }
}

//media query
@media (max-width:700px) {
    .wrapper{
        flex-direction: column;
    }
    .evalScoreForm {
        border-radius: .5rem;
        flex: 1 1 100%;
        min-width: 464px;
        margin-right: 0;
        transition:all .3s;
    }
    
    .evalSidePane {
        transition: all .3s;
        overflow: auto;
        border-radius: .5rem;
        flex: 1 1 100%;
        margin-top: .5em;
        margin-left: 0;
    }
}