.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.col-auto {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 8.33333%;
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-2 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 16.66667%;
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-3 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.33333%;
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-5 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 41.66667%;
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-6 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 58.33333%;
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-8 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 66.66667%;
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-9 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 83.33333%;
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-11 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 91.66667%;
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-12 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

@media (min-width: 576px) {
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-sm-1>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-sm-2>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-sm-3>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.row-cols-sm-4>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-sm-5>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-sm-6>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-sm-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-sm-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.33333%;
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-sm-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-sm-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-sm-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.66667%;
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-sm-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.33333%;
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-sm-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.66667%;
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-sm-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.33333%;
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-sm-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.66667%;
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-sm-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media (min-width: 768px) {
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-md-1>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-md-2>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-md-3>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.row-cols-md-4>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-md-5>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-md-6>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-md-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-md-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.33333%;
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-md-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-md-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-md-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.66667%;
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-md-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.33333%;
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-md-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.66667%;
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-md-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.33333%;
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-md-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.66667%;
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-md-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media (min-width: 992px) {
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-lg-1>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-lg-2>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-lg-3>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.row-cols-lg-4>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-lg-5>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-lg-6>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-lg-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-lg-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.33333%;
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-lg-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-lg-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-lg-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.66667%;
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-lg-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.33333%;
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-lg-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.66667%;
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-lg-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.33333%;
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-lg-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.66667%;
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-lg-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media (min-width: 1200px) {
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-xl-1>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-xl-2>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-xl-3>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.row-cols-xl-4>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-xl-5>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-xl-6>* {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-xl-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-xl-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.33333%;
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-xl-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.66667%;
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-xl-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-xl-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.66667%;
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-xl-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.33333%;
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-xl-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.66667%;
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-xl-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.33333%;
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-xl-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.66667%;
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-xl-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

// stylelint-disable declaration-no-important

.align-baseline    { vertical-align: baseline !important; } // Browser default
.align-top         { vertical-align: top !important; }
.align-middle      { vertical-align: middle !important; }
.align-bottom      { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top    { vertical-align: text-top !important; }
.h-100 {
	height: 100%;
}
.w-100 {
	width: 100%;
}