.form-group {
  margin: 0;
}

.form-group .form-control {
  min-height: 30px;
  height: 34px;
}

.k-input:not(:-webkit-autofill) {
  animation-name: autoFillEnd;
}

.k-input-solid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-input,
.k-picker {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: normal;
  text-align: start;
  box-shadow: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}

// Login start
.formGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 3;
  position: relative;
}

.formGroup .k-form {
  min-width: 440px;
}

.formInrG {
  background: #fff;
  padding: 15px 30px 30px 30px;
  border-radius: 0.25rem;
}

.k-floating-label-container > .k-label,
.k-floating-label-container.k-state-focused > .k-label,
.k-floating-label-container.k-focus > .k-label {
  top: 4px;
  transform: scale(1);
}

.k-floating-label-container > .k-label {
  max-width: 90%;
  font-size: 0.875rem;
}

.formInrG .hdrTrk-logo img {
  height: var(--brand-logo-login-height);
  width: auto;
}

.k-form .k-form-legend {
  margin-bottom: 0px;
  border-bottom: none;
}

.or-divider {
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  color: #333;
  width: 100%;
  text-align: center;
  position: relative;
}

.or-divider .orDividerSpan {
  display: inline-block;
  padding: 0;
  font-weight: 500;
}

.or-divider .orDividerSpan:after {
  left: 0;
  margin-right: 15px;
}

.or-divider .orDividerSpan:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  opacity: 0.5;
  border-top: solid 1px #333;
}
.orDividerSpanInner {
  background: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 9;
  display: inline-block;
}

.loginBg {
  background: url("../images/login-bg.jpg");
  z-index: 2;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -webkit-transition: background-image 300ms ease-in 200ms;
  transition: background-image 300ms ease-in 200ms;
}

.loginBg:after {
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  float: left;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 1;
}

.formLabel label {
  font-size: 14px;
}

.imgBtn .k-button-text {
  display: flex;
  align-items: center;
}

// Login end
.h-autoTextArea textarea {
  height: auto;
}

.editableInput {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
